import { 
    CONTACTO_LIST_FAIL,
    CONTACTO_LIST_REQUEST,
    CONTACTO_LIST_SUCCESS,

    CONTACTO_DETAIL_REQUEST,
    CONTACTO_DETAIL_SUCCESS,
    CONTACTO_DETAIL_FAIL,

    CONTACTO_ENVIARCORREO_REQUEST,
    CONTACTO_ENVIARCORREO_SUCCESS,
    CONTACTO_ENVIARCORREO_FAIL,

} from '../constants/contactoConstants'
import axios from 'axios'

export const listContactos = (id) => async (dispatch) => {
    try{
        dispatch({type: CONTACTO_LIST_REQUEST})

        const {data} = await axios.get(`/api/contactos/${id}/`)

        dispatch({
            type: CONTACTO_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: CONTACTO_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listContactoDetail = (id, tipo) => async (dispatch) => {
    try{
        dispatch({type: CONTACTO_DETAIL_REQUEST})

        const {data} = await axios.get(`/api/contactos/${id}/${tipo}/`)

        dispatch({
            type: CONTACTO_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: CONTACTO_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const enviarCorreoContacto = (correo) => async (dispatch, getState) => {
    try{
        dispatch({type: CONTACTO_ENVIARCORREO_REQUEST})

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers:{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/api/contactos/enviarCorreo/`,
            correo,
            config
        )

        dispatch({
            type: CONTACTO_ENVIARCORREO_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: CONTACTO_ENVIARCORREO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}