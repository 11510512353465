import { Outlet, Navigate } from "react-router-dom";

const Layout = ({userInfo, redirectPath='/login'}) =>{
    if(!userInfo){
        return <Navigate to={redirectPath} replace/>
    }
    return (
        <Outlet />
    )
}

export default Layout;