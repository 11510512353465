import { 
    CONTENIDO_VIDEOS_LIST_FAIL,
    CONTENIDO_VIDEOS_LIST_REQUEST,
    CONTENIDO_VIDEOS_LIST_SUCCESS,

} from '../constants/contenidoConstants'
import axios from 'axios'

export const listContenidoVideos = (cliente) => async (dispatch) => {
    try {
        dispatch({ type: CONTENIDO_VIDEOS_LIST_REQUEST })

        const { data } = await axios.get(`/api/contenidos/videos/${cliente}`)

        dispatch({
            type: CONTENIDO_VIDEOS_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CONTENIDO_VIDEOS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}