import React, {useState} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';

function VideoCard({video, loading, error}) {
    const [show, setShow] = useState(false);

  return (
        <div>
            
            <Card variant="outlined" sx={{ maxWidth: 345 }}>
                <CardActionArea onClick={() => setShow(true)}>
                    <CardMedia
                    component="img"
                    height="140"
                    image={video.thumbnail}
                    alt={video.name}
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {video.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {video.description}
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            
            <Modal
                size='lg'
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-xl"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-xl">
                    {video.name}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="align-items-center">
                        <ReactPlayer controls url={video.link} width="1024px" height="576px"/>
                    </Row>
                </Modal.Body>
            </Modal>
        
        </div>
  )
}

export default VideoCard