import React, {useState} from 'react'
import {Button, Form, Row, Col, Table } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';

import "./css/index.css";

import { enviarCorreoContacto } from '../actions/contactoActions';
import { useDispatch } from 'react-redux';
import Message from './Message';

import moment from 'moment';

function SeguroOptions(data) {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false);
  const [asunto, setAsunto] = useState('');
  const [mensaje, setMensaje] = useState('');

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(enviarCorreoContacto({
      'email':data.contacto.correo,
      'ccmail':data.contacto.correoCopia,
      'asunto':asunto,
      'mensaje':mensaje,
      'idTitular':data.idTitular,
      'idPoliza':data.idPoliza,
    }))
    setShow(false)
  }

  function formatearFecha(fecha) {
    const momentFecha = moment(fecha, 'YYYY-MM-DD');
    const fechaFormateada = momentFecha.format('DD/MM/YYYY');
    return fechaFormateada;
  }

  const handleClose = () => setShow(false);

  return (
    <Col sm={3} lg={3} className='text-center' style={{height:'100%'}}>
      <a href={data.link} target="_blank" rel="noreferrer" style={{textDecoration:'None'}} onClick={() => setShow(data.show)}>
        <div className='seguro-option pt-2 pb-2' style={{borderRight:"2px solid #ccc", borderLeft:"2px solid #ccc"}}>
            <Row className='m-2 pb-2'>
                <i className={data.icon} style={{fontSize:'40px'}}/>
            </Row>
            <Row className='m-2 pb-2'>
                <strong>{data.text}</strong>
            </Row>
        </div>
      </a>
      {data.contacto ? (
        <Modal
        size='m'
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-xl"
        centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-xl">
                Datos de Contacto
            </Modal.Title>
            </Modal.Header>
            <Form onSubmit={submitHandler}>
            <Modal.Body>
                <Row className="align-items-center m-2">
                  <Col>
                    <i className='fas fa-user-doctor'/> <strong>Nombre del Médico: </strong> {data.contacto.nombre}
                  </Col>
                </Row>
                <Row className="align-items-center m-2">
                  <Col>
                    <i className='fas fa-phone'/> <strong>Número de Teléfono: </strong> {data.contacto.celular}
                  </Col>
                </Row>
                <Row className="align-items-center m-2">
                  <Col>
                    <i className='fas fa-envelope'/> <strong>Correo electrónico: </strong> {data.contacto.correo}
                  </Col>
                </Row>
                <Row className="align-items-center m-2">
                  <Col>
                    <i className='fas fa-clock'/> <strong>Horario: </strong> {data.contacto.horario}
                  </Col>
                </Row>
                <br/>
                <h6>Enviar mensaje:</h6>
                <Form.Group controlId='asunto'>
                  <Form.Label>Asunto</Form.Label>
                  <Form.Control
                  type= 'text'
                  value={asunto}
                  onChange={(e)=> setAsunto(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <br/>
                <Form.Group controlId='mensaje'>
                  <Form.Label>Mensaje</Form.Label>
                  <Form.Control
                  as='textarea'
                  rows={3}
                  value={mensaje}
                  onChange={(e)=> setMensaje(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <br/>
                <span>Nota: Se enviará tu mensaje y recibirás respuesta a través de tu correo.</span>
            </Modal.Body>
            <Modal.Footer>
              <Button style={{background:'#003a70'}} type='submit'>Enviar Mensaje</Button>
              <Button variant="light" onClick={handleClose}>Cerrar</Button>
            </Modal.Footer>
            </Form> 
        </Modal>
      ): data.dependientes ?(
          <Modal
            size='xl'
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="example-modal-sizes-title-xl"
            centered
          >
              <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-xl">
                  Información de los Dependientes
              </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {data.dependientes.length>0 ? 
                <Table striped responsive className='table-sm table-responsive'>
                  <thead>
                      <tr>
                          <th>Nombre</th>
                          <th>Parentesco</th>
                          <th>Sexo</th>
                          <th>Fecha de Nacimiento</th>
                      </tr>
                  </thead>
                  <tbody>
                    {data.dependientes.map(dependiente => (
                      <tr key={dependiente._id}>
                        <td>{dependiente.nombres} {dependiente.apellidoPaterno} {dependiente.apellidoMaterno}</td>
                        <td>{dependiente.parentesco}</td>
                        <td>{dependiente.sexo}</td>
                        <td>{formatearFecha(dependiente.fechaNacimiento)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                :
                <Message variant='warning'>Aún no hay dependientes registrados</Message>}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="light" onClick={handleClose}>Cerrar</Button>
              </Modal.Footer>
          </Modal>
      ):(<div></div>)}
      
    </Col>
  )
}

export default SeguroOptions