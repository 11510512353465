export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_DETAIL_RESET = 'USER_DETAIL_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_PASSWORDRESET_REQUEST = 'USER_PASSWORDRESET_REQUEST'
export const USER_PASSWORDRESET_SUCCESS = 'USER_PASSWORDRESET_SUCCESS'
export const USER_PASSWORDRESET_FAIL = 'USER_PASSWORDRESET_FAIL'
export const USER_PASSWORDRESET_RESET = 'USER_PASSWORDRESET_RESET'

export const USER_CPASSWORDRESET_REQUEST = 'USER_CPASSWORDRESET_REQUEST'
export const USER_CPASSWORDRESET_SUCCESS = 'USER_CPASSWORDRESET_SUCCESS'
export const USER_CPASSWORDRESET_FAIL = 'USER_PASSWORDRESET_FAIL'
export const USER_CPASSWORDRESET_RESET = 'USER_CPASSWORDRESET_RESET'