import React,{ useEffect } from 'react'
import { Row, Col, Container, Button, Modal } from 'react-bootstrap';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import { listContactos } from '../../actions/contactoActions'
import { useDispatch, useSelector } from 'react-redux';

function ContactosScreen() {

    const dispatch = useDispatch()
    const contactoList = useSelector(state => state.contactoList)
    const{loading, error, contactos} = contactoList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let clave = 0
    if(userInfo){
        clave = userInfo.idCliente
    }

    useEffect(()=>{
        if(userInfo){
          dispatch(listContactos(clave))
        } 
    }, [dispatch, userInfo], clave)

  return (
    <div className='pt-5'>
            <h4>Equipo Sespec</h4>
            <hr/>
            <div>
                <Row className='p-2'>
                    {contactos.map(contacto => (
                        <Col className='d-flex align-items-center text-center justify-content-center mb-3' sm={4} lg={4}>
                            <Card sx={{ maxWidth: 345}}>
                                <CardActionArea className='ps-3' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <CardMedia
                                        component="img"
                                        image="https://beneficios-sistema.s3.amazonaws.com/media/imagenes/SespecUsuarios.png"
                                        alt="contacto Sespec"
                                        sx={{ maxWidth: '35%', height: 'auto', display: 'block', margin: 'auto' }}
                                        />
                                    <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {contacto.nombre}
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {contacto.tipoContacto}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <Row className="align-items-center mt-2">
                                            <Col>
                                                {contacto.celular == '-' ?
                                                    <div></div>
                                                : <div><i className='fas fa-phone'/> <strong>Número de Teléfono: </strong> {contacto.celular}</div>}
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center mt-2">
                                            <Col>
                                                <i className='fas fa-envelope'/> <strong>Correo electrónico: </strong> {contacto.correo}
                                            </Col>
                                        </Row>
                                    </Typography>
                                    {contacto.celular == '-' ?
                                        <Typography variant="body2" color="text.secondary">
                                            <br/><br/>
                                        </Typography>
                                    : <div></div>
                                    }
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
    </div>
  )
}

export default ContactosScreen