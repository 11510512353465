import { 
    TRAMITE_LIST_FAIL,
    TRAMITE_LIST_REQUEST,
    TRAMITE_LIST_SUCCESS,

    COMENTARIO_LIST_FAIL,
    COMENTARIO_LIST_REQUEST,
    COMENTARIO_LIST_SUCCESS,

    ARCHIVO_LIST_FAIL,
    ARCHIVO_LIST_REQUEST,
    ARCHIVO_LIST_SUCCESS,

    COMENTARIO_CREATE_FAIL,
    COMENTARIO_CREATE_REQUEST,
    COMENTARIO_CREATE_SUCCESS,

    TRAMITE_DETAIL_REQUEST,
    TRAMITE_DETAIL_SUCCESS,
    TRAMITE_DETAIL_FAIL,

    TRAMITE_CREATE_REQUEST,
    TRAMITE_CREATE_SUCCESS,
    TRAMITE_CREATE_FAIL,

} from '../constants/tramiteConstants'
import axios from 'axios'

export const createTramite = (tramite) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TRAMITE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/tramites/create/`,
            tramite,
            config
        )
        dispatch({
            type: TRAMITE_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: TRAMITE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listTramites = (id) => async (dispatch) => {
    try{
        dispatch({type: TRAMITE_LIST_REQUEST})

        const {data} = await axios.get(`/api/tramites/${id}/`)

        dispatch({
            type: TRAMITE_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: TRAMITE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listTramiteDetail = (idTitular, id) => async (dispatch) => {
    try{
        dispatch({type: TRAMITE_DETAIL_REQUEST})

        const {data} = await axios.get(`/api/tramites/${idTitular}/${id}/`)

        dispatch({
            type: TRAMITE_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: TRAMITE_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listComentario = (id) => async (dispatch) => {
    try{
        dispatch({type: COMENTARIO_LIST_REQUEST})

        const {data} = await axios.get(`/api/tramites/comentarios/${id}/`)

        dispatch({
            type: COMENTARIO_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: COMENTARIO_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createComentario = (comentario) => async (dispatch, getState) => {
    try {
        dispatch({
            type: COMENTARIO_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/tramites/comentario/create/`,
            comentario,
            config
        )
        dispatch({
            type: COMENTARIO_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: COMENTARIO_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listArchivoTramite = (id, tipo) => async (dispatch) => {
    try{
        dispatch({type: ARCHIVO_LIST_REQUEST})

        const {data} = await axios.get(`/api/tramites/archivos/${id}/${tipo}`)

        dispatch({
            type: ARCHIVO_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: ARCHIVO_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}