import React, { useState } from 'react'
import FormContainer from '../../../components/FormContainer'
import { Form, Button, FloatingLabel, Row, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { userPasswordReset } from '../../../actions/userActions'
import { USER_CPASSWORDRESET_RESET } from '../../../constants/userConstants'

function PasswordResetScreen() {
  const [email, setEmail] = useState('')

  const dispatch = useDispatch()
  let navigate = useNavigate()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(userPasswordReset(email))
    dispatch({ type: USER_CPASSWORDRESET_RESET })
    navigate('/login')
  }
  return (
    <FormContainer>
      <br/>
      <h1>Recuperar Contraseña</h1>
      <br/>
      <Form onSubmit={submitHandler}>
        <span><strong>Se enviará un correo con las instrucciones para restablecer tu contraseña</strong></span>
        <br/><br/>
        <Form.Group controlId='email'>
            <FloatingLabel
              label="Dirección de Correo"
              className="mb-3"
            >
              <Form.Control
              type= 'email'
              value={email}
              onChange={(e)=> setEmail(e.target.value)}
              ></Form.Control>
            </FloatingLabel>
          </Form.Group>
          <br/>
          <Row>
              <Col sm={2} lg={6}>
                  <Button className='btn' style={{background:'#47d7ac', width:'100%'}} type='submit'>
                      Enviar Correo
                  </Button>
                  <br/>
                  <br/>
              </Col>
              <Col sm={2} lg={6}>
                  <Link to={'/login'} className='btn btn-light' style={{width:'100%'}} variant='light'>
                      Iniciar Sesión
                  </Link>
              </Col>
          </Row>
      </Form>
    </FormContainer>
  )
}

export default PasswordResetScreen