import { 
    DEPENDIENTE_LIST_FAIL,
    DEPENDIENTE_LIST_REQUEST,
    DEPENDIENTE_LIST_SUCCESS,

    DEPENDIENTE_DETAIL_REQUEST,
    DEPENDIENTE_DETAIL_SUCCESS,
    DEPENDIENTE_DETAIL_FAIL,

    DEPENDIENTE_USER_REQUEST,
    DEPENDIENTE_USER_SUCCESS,
    DEPENDIENTE_USER_FAIL,

    DEPENDIENTE_UPDATE_REQUEST,
    DEPENDIENTE_UPDATE_SUCCESS,
    DEPENDIENTE_UPDATE_FAIL,
    DEPENDIENTE_UPDATE_RESET,

    DEPENDIENTE_CREATE_REQUEST,
    DEPENDIENTE_CREATE_SUCCESS,
    DEPENDIENTE_CREATE_FAIL, 
    DEPENDIENTE_CREATE_RESET,

} from '../constants/dependienteConstants'

export const dependienteListReducer= (state = {dependientes:[]}, action) =>{
    switch(action.type){
        case DEPENDIENTE_LIST_REQUEST:
            return {loading:true, ...state}

        case DEPENDIENTE_LIST_SUCCESS:
            return {loading:false, dependientes: action.payload}

        case DEPENDIENTE_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}