import React, {useEffect}  from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import SeguroOptions from '../../../components/SeguroOptions'
import SeguroTitular from '../../../components/SeguroTitular'

import { useDispatch, useSelector } from 'react-redux'

import { listTitularUser } from '../../../actions/titularActions'
import { listPolizaGMMDetail } from '../../../actions/polizaGMMActions'
import { listDependientes } from '../../../actions/dependienteActions';
import { listContactoDetail } from '../../../actions/contactoActions'

import SeguroPolizaGMM from '../../../components/SeguroPolizaGMM'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message' 


function SeguroScreen() {
  const dispatch = useDispatch()

  const titularUser = useSelector(state => state.titularUser)
  const{titular} = titularUser

  const dependienteList = useSelector(state => state.dependienteList)
  const{dependientes} = dependienteList

  const contactoDetail = useSelector(state => state.contactoDetail)
  const{contacto} = contactoDetail

  const polizaGMMDetail = useSelector(state => state.polizaGMMDetail)
  const{error: errorD, loading: loadingD, url, polizaGMM} = polizaGMMDetail

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let clave = 0
  if(userInfo){
    clave = userInfo.id
  }


  useEffect(()=>{
    if(!titular.nombres){
      dispatch(listTitularUser(clave))
    } else{
      dispatch(listPolizaGMMDetail(titular._id))
      dispatch(listDependientes(titular._id))
      dispatch(listContactoDetail(titular._id, "Médico"))
    }
  }, [dispatch, titular], clave)

  return (
    <div className='pt-5'>
        <h4>Mi Seguro</h4>
        <hr/>
        {loadingD ? <Loader/>
          : errorD ? <Message variant="danger">{errorD}</Message>
          :
        <div>
        <Row>
          <Col>
            <Card className='p-4'>
              <Row> 
                <SeguroOptions icon="fas fa-file-invoice" text="Descargar Poliza GMM" link={url}/>
                <SeguroOptions icon="fas fa-headset" text="Contacta a tu Médico" show={true} contacto={contacto} idTitular={titular._id} idPoliza={polizaGMM._id}/>
                <SeguroOptions icon="fas fa-people-roof" text="Dependientes" show={true} dependientes={dependientes}/>
                <SeguroOptions icon="fas fa-hospital" text="Red Hospitalaria" link={polizaGMM.planLink} />
              </Row>
            </Card>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm={6} lg={6} className="mb-3">
            <SeguroTitular titular={titular}/>
          </Col>
          <Col sm={6} lg={6}>
            <SeguroPolizaGMM poliza={polizaGMM}/>
          </Col>
        </Row>
        </div>
        }
    </div> 
  )
}

export default SeguroScreen