export const CONTACTO_LIST_REQUEST = 'CONTACTO_LIST_REQUEST'
export const CONTACTO_LIST_SUCCESS = 'CONTACTO_LIST_SUCCESS'
export const CONTACTO_LIST_FAIL = 'CONTACTO_LIST_FAIL'

export const CONTACTO_DETAIL_REQUEST = 'CONTACTO_DETAIL_REQUEST'
export const CONTACTO_DETAIL_SUCCESS = 'CONTACTO_DETAIL_SUCCESS'
export const CONTACTO_DETAIL_FAIL = 'CONTACTO_DETAIL_FAIL'

export const CONTACTO_ENVIARCORREO_REQUEST = 'CONTACTO_ENVIARCORREO_REQUEST'
export const CONTACTO_ENVIARCORREO_SUCCESS = 'CONTACTO_ENVIARCORREO_SUCCESS'
export const CONTACTO_ENVIARCORREO_FAIL = 'CONTACTO_ENVIARCORREO_FAIL'