import { 
    DEPENDIENTE_LIST_FAIL,
    DEPENDIENTE_LIST_REQUEST,
    DEPENDIENTE_LIST_SUCCESS,

    DEPENDIENTE_DETAIL_REQUEST,
    DEPENDIENTE_DETAIL_SUCCESS,
    DEPENDIENTE_DETAIL_FAIL,

    DEPENDIENTE_USER_REQUEST,
    DEPENDIENTE_USER_SUCCESS,
    DEPENDIENTE_USER_FAIL,

    DEPENDIENTE_UPDATE_REQUEST,
    DEPENDIENTE_UPDATE_SUCCESS,
    DEPENDIENTE_UPDATE_FAIL,

    DEPENDIENTE_CREATE_REQUEST,
    DEPENDIENTE_CREATE_SUCCESS,
    DEPENDIENTE_CREATE_FAIL,

} from '../constants/dependienteConstants'
import axios from 'axios'

export const listDependientes = (id) => async (dispatch) => {
    try{
        dispatch({type: DEPENDIENTE_LIST_REQUEST})

        const {data} = await axios.get(`/api/dependientes/${id}`)

        dispatch({
            type: DEPENDIENTE_LIST_SUCCESS,
            payload: data
        })
        
    }catch(error){
        dispatch({
            type: DEPENDIENTE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}