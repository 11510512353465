import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap'

import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'

function Header() {
  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }
  return (
    <header>
      {userInfo ? (
          <Navbar collapseOnSelect fixed="top" expand="lg" bg="dark" variant="dark">
            {userInfo.isTitular ? 
            <Container>
              <LinkContainer to={`/t-home`}> 
                <Navbar.Brand>
                  Sespec
                </Navbar.Brand>
              </LinkContainer>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">  
                  <Nav className="me-auto">
                    <LinkContainer to={`/t-seguro`}> 
                      <Nav.Link><i className="fas fa-user-shield"></i> Mi Seguro</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={`/t-guias`}> 
                      <Nav.Link><i className="fas fa-chalkboard-user"></i> Guias</Nav.Link>
                    </LinkContainer>
                    
                  </Nav>      
                <Nav>
                  
                  <LinkContainer to={`/t-contactos`}> 
                      <Nav.Link><i className="fas fa-address-book"></i> Contactos</Nav.Link>
                  </LinkContainer>
                  <NavDropdown title={userInfo.email} id="collasible-nav-dropdown">
                    <LinkContainer to={`/t-profile`}> 
                    <NavDropdown.Item><i className="fas fa-user"/> Mi Perfil</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={logoutHandler}><i className="fas fa-arrow-right-from-bracket"/> Cerrar Sesión</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
            :
            <Container>
              <LinkContainer to={`/m-home`}> 
                <Navbar.Brand>
                  Sespec
                </Navbar.Brand>
              </LinkContainer>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">  
            <Nav className="me-auto">
              <LinkContainer to={`/m-asegurados`}> 
                <Nav.Link><i className="fas fa-user-group"></i> Mis Asegurados</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav>
            <NavDropdown title={userInfo.email} id="collasible-nav-dropdown">
              <LinkContainer to={`/t-profile`}> 
              <NavDropdown.Item><i className="fas fa-user"/> Mi Perfil</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={logoutHandler}><i className="fas fa-arrow-right-from-bracket"/> Cerrar Sesión</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
            }
          </Navbar>
      ): (
        <div></div>
      )}
    </header> 
  )
}

export default Header