import React from 'react'
import { Container, Row, Col, Button} from 'react-bootstrap'
import LinkCard from '../../../components/LinkCard'
import './index.css';
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

function ConfirmationScreen() {
    const match = useParams()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

  return (
    <div className='pt-5'>
        <Container>
            <Row className='justify-content-md-center'>
                <Col md={8} xs={12}>
                    <h1 className='text-center'>Confirmación de Trámite</h1>
                    <hr/>
                    <p className='text-center'>
                    Muchas gracias por utilizar el Sitema de Beneficios Sespec para dar inicio a tu trámite. Uno de nuestros médicos
                    acaba de recibir una notificación para iniciar la revisión de tus documentos. Recibiras un correo electronico a
                    la dirección <span style={{color:'#248BF2'}}><strong>{userInfo.email}</strong> </span> con instrucciones para darle seguimiento a tu trámite.
                    </p>
                    <br/>
                    <Row>
                        <Col sm={2} lg={6}className='text-end'>
                            <Link to={`/t-tramites/${match.id}`} className="btn btn-dark" style={{width:'100%'}} >
                                Ver Trámite
                            </Link>
                            <br/>
                            <br/>
                        </Col>
                        <Col sm={2} lg={6}>
                            <Link to={`/t-tramites`} className="btn btn-light" style={{width:'100%'}} variant='light'>
                                Historial Trámites
                            </Link>
                        </Col>
                    </Row>
                    <br/>
                    <Row className="m-4">
                        <LinkCard link="/t-reembolso" icono="fas fa-file-invoice-dollar" titulo="Reembolso"/> 
                        <LinkCard link="/t-cirugia" icono="fas fa-user-doctor" titulo="P.Cirugía"/>
                        <LinkCard link="/t-seguro" icono="fas fa-user-shield" titulo="Mi Seguro"/>
                        <LinkCard link="/t-guias" icono="fas fa-chalkboard-user" titulo="Guías"/>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ConfirmationScreen