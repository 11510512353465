import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

function LinkCard(data) {
    let navigate = useNavigate()
    const handleClick = () => {
        navigate(data.link)
    }
  return (
    <Col className='text-center'>
        <Card variant="outlined" sx={{ maxWidth: 200}}>
            <CardActionArea onClick={handleClick}>
                <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                    <i className={data.icono}></i>
                </Typography>
                <Typography variant="h6" component="div">
                    {data.titulo}
                </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Col>
  )
}

export default LinkCard