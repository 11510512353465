import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Container, Table, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { listTitulares } from '../../../actions/titularActions'
import SearchBox from '../../../components/SearchBox'
import { Link } from 'react-router-dom'

function AseguradosScreen() {
  const dispatch = useDispatch()

  const titularList = useSelector(state => state.  titularList)
  const{error, loading, titulares, page, pages} = titularList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let clave = 0
  if(userInfo){
    clave = userInfo.idCliente
  }

  const keyword = useLocation().search

  useEffect(()=>{

    dispatch(listTitulares(clave, keyword))
    
  }, [dispatch, keyword, clave])

  return (
    <div className='pt-5'>
      <Container>
        <Row className='align-items-center'>
          <Col>
            <h4>ASEGURADOS</h4>
          </Col>
          <Col>
            <SearchBox/>
          </Col>
        </Row>
        <hr/>
        
        <Table striped responsive className='table-sm'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Cliente</th>
                        <th>Nombre</th>
                        <th>Correo</th>
                        <th>Sexo</th>
                        <th>RFC</th>
                        <th>
                          <Link to={`/m-asegurados/create`}>
                            Nuevo Asegurado
                          </Link>
                        </th>
                    </tr>
                </thead>
                <tbody>
                  {titulares.map(titular =>(
                    <tr key={titular._id}>
                      <td>{titular._id}</td>
                      <td>{titular.cliente}</td>
                      <td>{titular.nombres} {titular.apellidoPaterno} {titular.apellidoMaterno}</td>
                      <td>{titular.email}</td>
                      <td>{titular.sexo}</td>
                      <td>{titular.rfc}</td>
                      <td>
                        <LinkContainer to={`/m-asegurados/edit/${titular._id}`}>
                          <span className='p-2'><a href='#' className='btn-sm'><i className='fas fa-pen-to-square btn-sm'></i></a></span> 
                        </LinkContainer>
                        <span className='p-2'><a href='#' className='btn-sm'><i className='fas fa-trash btn-sm'></i></a></span>
                      </td>
                    </tr>
                  ))}
                </tbody>
        </Table>
      </Container>
      
    </div>
  )
}

export default AseguradosScreen