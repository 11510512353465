import { Route,Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Header from "./components/Header";
import Footer from "./components/Footer";
import Layout from './components/Layout'

import LoginScreen from "./screens/LoginScreen";
import PasswordResetScreen from './screens/PasswordScreens/PasswordResetScreen';
import ConfirmPasswordResetScreen from './screens/PasswordScreens/ConfirmPasswordResetScreen';
import GuiasScreen from "./screens/GuiasScreen";

import HomeScreen from "./screens/TitularScreens/HomeScreen";
import SeguroScreen from './screens/TitularScreens/SeguroScreen';
import ProfileScreen from "./screens/TitularScreens/ProfileScreen";

import AseguradosScreen from './screens/MedicoScreens/AseguradosScreen';
import AseguradosEditScreen from './screens/MedicoScreens/AseguradosEditScreen';
import MenuMedicoScreen from './screens/MedicoScreens/MenuMedicoScreen';
import AseguradosCreateScreen from './screens/MedicoScreens/AseguradosCreateScreen';
import ReembolsoScreen from './screens/TitularScreens/ReembolsoScreen';
import CirugiaScreen from './screens/TitularScreens/CirugiaScreen';
import ConfirmationScreen from './screens/TitularScreens/ConfirmationScreen';
import TramitesScreen from './screens/TitularScreens/TramitesScreen';
import TramiteScreen from './screens/TitularScreens/TramiteScreen';
import { useMediaQuery } from 'react-responsive';
import ContactosScreen from './screens/ContactosScreen';

function App() {
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const isSmallScreen = useMediaQuery({ maxWidth: 576 });
  return (
    <div className="App">
      <Header/>
      <main className={isSmallScreen ? 'py-2 px-4' : 'py-4 px-5'}>
        <Routes>
          {/*PUBLIC ROUTES*/}
          <Route path="login" element={<LoginScreen/>}/>
          <Route path="olvidaste-contrasena" element={<PasswordResetScreen/>}/>
          <Route path="cambio-contrasena/:id/:token" element={<ConfirmPasswordResetScreen/>}/>
          <Route path="/" element={<Layout userInfo={userInfo}/>}>
            {/*PRIVATE ROUTES FOR TITULARES*/}
            <Route path="t-home" element={<HomeScreen/>}/>
            <Route path="t-seguro" element={<SeguroScreen/>}/>
            <Route path="t-guias" element={<GuiasScreen/>}/>
            <Route path="t-profile" element={<ProfileScreen/>}/>
            <Route path="t-contactos" element={<ContactosScreen/>}/>
            <Route path="t-reembolso" element={<ReembolsoScreen/>}/>
            <Route path="t-cirugia" element={<CirugiaScreen/>}/>
            <Route path="t-confirmacion/:id" element={<ConfirmationScreen/>}/>
            <Route path="t-tramites" element={<TramitesScreen/>}/>
            <Route path="t-tramites/:id" element={<TramiteScreen/>}/>

            {/*PRIVATE ROUTES FOR MEDICOS*/}
            <Route path="m-home" element={<MenuMedicoScreen/>}/>
            <Route path="m-asegurados" element={<AseguradosScreen/>}/>
            <Route path="m-asegurados/create" element={<AseguradosCreateScreen/>}/>
            <Route path="m-asegurados/edit/:id" element={<AseguradosEditScreen/>}/>
          </Route>
        </Routes>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
