import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Card, Row, Col, ProgressBar, Button, Table, Modal, Form  } from 'react-bootstrap';
import Message from '../../../components/Message';

import moment from 'moment';

import { listTitularUser } from '../../../actions/titularActions'
import { listTramiteDetail, listComentario, createComentario, listArchivoTramite } from '../../../actions/tramiteActions'
import { listContactoDetail } from '../../../actions/contactoActions'
import { enviarCorreoContacto } from '../../../actions/contactoActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';

function TramiteScreen() {
    const dispatch = useDispatch()
    const match = useParams()

    const [show, setShow] = useState(false)
    const [info, setInfo] = useState(false)
    const [medico, setMedico] = useState(false)
    const [comentario, setComentario] = useState(false)
    const [document, setDocument] = useState(false)
    const [index, setIndex] = useState(0)
    const [file, setFile] = useState('')
    const [archivo, setArchivo] = useState('')

    const [exito, setSuccess] = useState(false)
    const [err, setErr] = useState(false)

    const [asunto, setAsunto] = useState('')
    const [mensaje, setMensaje] = useState('')

    const archivoList = useSelector(state => state.archivoList)
    const{loading, error, archivos, status} = archivoList

    const tramiteDetail = useSelector(state => state.tramiteDetail)
    const{error: errorTramite,tramite} = tramiteDetail

    const comentarioList = useSelector(state => state.comentarioList)
    const{comentarios} = comentarioList

    const contactoDetail = useSelector(state => state.contactoDetail)
    const{contacto} = contactoDetail

    const titularUser = useSelector(state => state.titularUser)
    const{titular} = titularUser

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const comentarioCreate = useSelector(state => state.comentarioCreate)
    const {success} = comentarioCreate

    let clave = 0
    if(userInfo){
        clave = userInfo.id
    }

    useEffect(()=>{
        if(!titular.nombres){
          dispatch(listTitularUser(clave))
        } else if(!errorTramite){
            if(!tramite.aseguradoAfectado){
                dispatch(listTramiteDetail(titular._id, match.id ))
                dispatch(listContactoDetail(titular._id, "Médico"))
            } else{
                dispatch(listArchivoTramite(tramite._id,"Reembolso"))
                dispatch(listComentario(tramite._id))
            }
        }
      }, [dispatch, titular, tramite, success, exito], clave)

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(enviarCorreoContacto({
            'email': contacto.correo,
            'ccmail': contacto.correoCopia,
            'asunto': asunto,
            'mensaje': mensaje,
            'idTitular': titular._id,
            'tramite': tramite.folio,
        }))
        setShow(false)
    }

    const submitHandlerComentario = (e) => {
        e.preventDefault()
        dispatch(createComentario({
            'tramite': tramite._id,
            'mensaje': mensaje,
            'autor': 'Titular',
        }))
        setShow(false)
    }

    const submitHandlerDocumento = async (e) => {
        e.preventDefault()
        setShow(false)
        setSuccess(false)
        const nIndex = index + 1
        const formData = new FormData()
        formData.append('idTramite', tramite._id)
        formData.append('tipoTramite', tramite.tipoTramite)
        formData.append('nombreAnterior', archivos[index])
        formData.append('index', nIndex)
        formData.append('archivo', archivo)
        
        try {

            const config = {
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.put('/api/tramites/archivos/update/', formData, config)

            setSuccess(true)

        } catch (error) {
            setErr(error)
        }
    }

    const uploadFileHandler = async (e) => {
        setFile('fa-regular fa-circle-check')
        setArchivo(e.target.files[0])
    }

    const handleInfo = () => {
        setInfo(true)
        setShow(true)
    }
    
    const handleMedico = () => {
        setMedico(true)
        setShow(true)
    }

    const handleComentario = () => {
        setComentario(true)
        setShow(true)
    }

    function handleDocument(indexV){
        setIndex(indexV)
        setDocument(true)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setDocument(false)
        setComentario(false)
        setInfo(false)
        setMedico(false)
    }


    function obtenerNombreApellido(nombreCompleto) {
        nombreCompleto.toLowerCase().replace(/(^|\s)\S/g, (letra) => letra.toUpperCase());
        const arregloNombre = nombreCompleto.split(" ");
        const primerNombre = arregloNombre[0];
        const primerApellido = arregloNombre[arregloNombre.length - 2];

        return "" + primerNombre + " " + primerApellido;
 }

    let asegurado = ""
    if(!errorTramite){
        if(tramite.aseguradoAfectado){
            asegurado = obtenerNombreApellido(tramite.aseguradoAfectado)
        }
    }

    function getDateDiffInDays(dateString) {
        const fecha1 =  moment(dateString).format('DD/MM/YYYY');
        const fecha2 = moment().format('DD/MM/YYYY');

        const date1 = moment(fecha1, 'DD/MM/YYYY').toDate();
        const date2 = moment(fecha2, 'DD/MM/YYYY').toDate();
      
        const diffInMs = Math.abs(date2 - date1);
        const diffInDias = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
      
        return diffInDias;
    }

    let diffInDays = 0
    if(!errorTramite){      
        if(tramite.aseguradoAfectado){
            diffInDays = getDateDiffInDays(tramite.etapa1)
        }
    }

  return (
    <div className='pt-5'>
        {errorTramite ? <Message variant="warning">No tienes acceso a este trámite. Si crees que deberías ver esta información contacta al responsable de la Plataforma</Message>
        : loading ? <Loader/>
        : error ? <Message variant="danger">{error}</Message>
        :
        <div>
        <Row>
            <Col>
                <h4>Tramite: {tramite.folio}</h4> 
            </Col>
            <Col>
                <Button style={{ borderRadius: '10px' }} className='btn btn-dark btn-sm d-flex ms-auto' onClick={handleInfo}> <i className='fas fa-info' style={{color:'white'}}/></Button>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col sm={3} m={3} lg={3}>
                <i style={{color:'#87CEEB'}}className='fas fa-circle'/> Asegurado: {asegurado}
            </Col>
            <Col sm={3} m={3} lg={3}>
                <i style={{color:'#00C957'}}className='fas fa-circle'/> Trámite: {tramite.tipoTramite}
            </Col>
            <Col sm={3} m={3} lg={3}>
                <i style={{color:'#FF5C5C'}}className='fas fa-circle'/> Médico: {tramite.medico}
            </Col>
            <Col sm={3} m={3} lg={3}>
                <i style={{color:'#FFD700'}}className='fas fa-circle'/> Status: {tramite.status}
            </Col>
        </Row>
        <hr/>
        <br/>
        <Card className='p-2 pt-5 pb-5'>
            <Row>
                <Col className='text-center'>
                    Revisión del Médico
                </Col>
                <Col className='text-center'>
                    Revisión de la Aseguradora
                </Col>
            </Row>
            <Row>
            <Col className='text-center'><ProgressBar animated now='25' variant={diffInDays<3 ? 'success' : diffInDays<5 ? 'warning' : 'danger'} style={{ height: '30px', borderRadius:'10px'}} /></Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    Etapa 1
                </Col>
                <Col className='text-center'>
                    Etapa 2
                </Col>
            </Row>
        </Card>
        <br/>
        <Row>
            <Col sm={6} m={6} lg={6}>
                <h6>Documentación Enviada:</h6>
                <hr/>
                <Table striped responsive className='table-sm table-responsive'>
                    <thead>
                        <tr className='text-center'>
                            <th>Nombre</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {archivos ? archivos.map((archivo, index) =>(
                            <tr>
                                <td>{archivo}</td>
                                <td className='text-center'>{status[index]}</td>
                                {status[index] == 'Rechazado' ? 
                                <td> <i className='fas fa-pen-to-square' onClick={() => handleDocument(index)}/></td>
                                :
                                <td></td>}
                            </tr>
                        )):
                        <tr></tr>}
                    </tbody>
                </Table>
            </Col>
            <Col sm={6} m={6} lg={6}>
            <h6>Comentarios:</h6>
            <hr/>
            <Row>
                <Col sm={6}>
                    <Button className='m-2' style={{width:'100%'}} variant='success' onClick={handleComentario}>Nuevo Comentario</Button>
                </Col>
                <Col sm={6}>
                    <Button style={{width:'100%'}} className='btn btn-dark m-2' onClick={handleMedico}>Contactar a tu médico</Button>
                </Col>
            </Row>
            <br/>
            {comentarios.length>0 ? 
                <div>
                    <h6>Inicio de la conversación:</h6>
                    <div className='p-1' style={{maxHeight:'40vh', maxWidth:'100%', overflowY:'auto', overflowX:'hidden', backgroundColor:'#fbfbfb'}}>
                    {comentarios.map(coment =>(
                        <Row id={coment._id}>
                            <Col>
                                <Card rounded className={coment.autor == 'Titular' ? 'p-2 d-flex me-auto':'p-2 d-flex ms-auto'} style={coment.autor == 'Titular' ? {backgroundColor:'#F0F8FF', width:'80%'} : {backgroundColor:'#FFFFFF',  width:'80%'}}>
                                    <Row>
                                        <Col>
                                            <span style={{fontSize:'12px', color:'#bbb'}}>{coment.autor}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p>{coment.mensaje}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span style={{fontSize:'12px', color:'#bbb'}}>{coment.fecha}</span>
                                        </Col>
                                    </Row>
                                </Card>
                                <br/>
                            </Col>
                            <br/>
                        </Row>
                    ))}
                    </div>
                </div>
            : 
                <Message variant='warning'>No hay comentarios hasta el momento</Message>
            }
            </Col>
        </Row>
            {info ? (
                <Modal
                size='m'
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-xl"
                centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-xl">
                            Información de Trámite
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h6>Status del Trámite</h6>
                            <hr/>
                            <p className='text-justify'><strong style={{color:'black'}}>Etapa 1: </strong>Una vez iniciado el proceso de trámite (Reembolso / P. de Cirugía) el médico recibe una notificación con tu
                            solicitud. El médico revisará todos los documentos enviados para validar que no haya ningún error u omisión. En caso de alguna
                            observación, el médico dejará un comentario solicitando cambios en algún documento.
                            </p>
                            <br/>
                            <span>Observaciones</span>
                            <br/>
                            <ul>
                                <li>
                                    El tiempo límite de respuesta por parte del médico es de 2 días la barra permanecera en verde durante este periodo. 
                                </li>
                                <li>
                                    De 2do a 3er día la barra cambiará a color amarillo y deberá recibir algún justificante de su médico.
                                </li>
                                <li>
                                    Del 3er a 4to día la barra cambiará a color rojo y un superior se pondra en contacto directo con usted.
                                </li>
                            </ul>
                            <br/>
                            <p className='text-justify'><strong style={{color:'black'}}>Etapa 2: </strong>Acabada la revisión del médico, si no hay ninguna observación se manda la solicitud a la Aseguradora
                            para solicitar la carta de aprobación. Una vez teniendo la carta se le hará llegar por correo y podrá descargarla por la plataforma.
                            </p>
                            <br/>
                            <span>Observaciones</span>
                            <br/>
                            <ul>
                                <li>
                                    El tiempo límite de respuesta por parte de la aseguradora es de 2 días la barra permanecera en verde durante este periodo. 
                                </li>
                                <li>
                                    De 2do a 3er día la barra cambiará a color amarillo y deberá recibir algún justificante de su médico.
                                </li>
                                <li>
                                    Del 3er a 4to día la barra cambiará a color rojo y un superior se pondra en contacto directo con usted.
                                </li>
                            </ul>
                            <br/>
                            <p className='text-justify'>El trámite deberá durar un tiempo máximo de 8 días hábiles.</p>

                        </div>
                
                    </Modal.Body>
                </Modal>
            ): medico ? (
                <Modal
                size='m'
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-xl"
                centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-xl">
                        Datos de Contacto
                    </Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={submitHandler}>
                    <Modal.Body>
                        <Row className="align-items-center m-2">
                            <Col>
                                <i className='fas fa-user-doctor'/> <strong>Nombre del Médico: </strong> {contacto.nombre}
                            </Col>
                        </Row>
                        <Row className="align-items-center m-2">
                            <Col>
                                <i className='fas fa-phone'/> <strong>Número de Teléfono: </strong> {contacto.celular}
                            </Col>
                        </Row>
                        <Row className="align-items-center m-2">
                            <Col>
                                <i className='fas fa-envelope'/> <strong>Correo electrónico: </strong> {contacto.correo}
                            </Col>
                        </Row>
                        <Row className="align-items-center m-2">
                            <Col>
                                <i className='fas fa-clock'/> <strong>Horario: </strong> {contacto.horario}
                            </Col>
                        </Row>
                        <br/>
                        <h6>Enviar mensaje:</h6>
                        <Form.Group controlId='asunto'>
                        <Form.Label>Asunto</Form.Label>
                        <Form.Control
                        type= 'text'
                        value={asunto}
                        onChange={(e)=> setAsunto(e.target.value)}
                        ></Form.Control>
                        </Form.Group>
                        <br/>
                        <Form.Group controlId='mensaje'>
                        <Form.Label>Mensaje</Form.Label>
                        <Form.Control
                        as='textarea'
                        rows={3}
                        value={mensaje}
                        onChange={(e)=> setMensaje(e.target.value)}
                        ></Form.Control>
                        </Form.Group>
                        <br/>
                        <span>Nota: Se enviará tu mensaje y recibirás respuesta a través de tu correo.</span>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button style={{background:'#003a70'}} type='submit'>Enviar Mensaje</Button>
                    <Button variant="light" onClick={handleClose}>Cerrar</Button>
                    </Modal.Footer>
                    </Form> 
                </Modal>
            ): comentario ? (
                <Modal
                    size='m'
                    show={show}
                    onHide={() => setShow(false)}
                    aria-labelledby="example-modal-sizes-title-xl"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-xl">
                        Realiza un comentario
                    </Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={submitHandlerComentario}>
                    <Modal.Body>
                        <Form.Group controlId='mensaje'>
                        <Form.Label>Mensaje</Form.Label>
                        <Form.Control
                        as='textarea'
                        rows={3}
                        value={mensaje}
                        onChange={(e)=> setMensaje(e.target.value)}
                        ></Form.Control>
                        </Form.Group>
                        <br/>
                        <span>Nota: Este comnetario solo lo verá tu Médico para este trámite en particular</span>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button style={{background:'#003a70'}} type='submit'>Enviar Comentario</Button>
                    <Button variant="light" onClick={handleClose}>Cerrar</Button>
                    </Modal.Footer>
                    </Form> 
                </Modal>
            )
            : document ?(
                <Modal
                    size='m'
                    show={show}
                    onHide={() => setShow(false)}
                    aria-labelledby="example-modal-sizes-title-xl"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-xl">
                        Editar documento: {archivos[index]}
                    </Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={submitHandlerDocumento}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Nuevo documento <i style={{color:'#4bbf73'}} className={file}></i></Form.Label>
                            <Form.Control
                                id='0'
                                type='file'
                                accept=".pdf"
                                name='archivo1'
                                required
                                onChange={uploadFileHandler}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                        <span>Nota: Una vez guardado el archivo ya no se puede editar hasta que el médico lo revise</span>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button style={{background:'#003a70'}} type='submit'>Enviar archivo</Button>
                    <Button variant="light" onClick={handleClose}>Cerrar</Button>
                    </Modal.Footer>
                    </Form> 
                </Modal>
            ):(<div></div>)}
        </div>
        }
    </div>
  )
}

export default TramiteScreen