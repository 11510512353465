import { 
    POLIZA_GMM_FAIL,
    POLIZA_GMM_REQUEST,
    POLIZA_GMM_SUCCESS,
} from '../constants/polizaGMMConstants'

import axios from 'axios'

export const listPolizaGMMDetail = (id) => async (dispatch) => {
    try{
        dispatch({type: POLIZA_GMM_REQUEST})

        const {data} = await axios.get(`/api/polizaGMM/${id}`)

        dispatch({
            type: POLIZA_GMM_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: POLIZA_GMM_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}