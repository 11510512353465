import { 
    USER_LOGIN_REQUEST, 
    USER_LOGIN_SUCCESS, 
    USER_LOGIN_FAIL, 

    USER_LOGOUT,

    USER_DETAIL_RESET,

    USER_UPDATE_PROFILE_REQUEST, 
    USER_UPDATE_PROFILE_SUCCESS, 
    USER_UPDATE_PROFILE_FAIL, 

    USER_PASSWORDRESET_REQUEST,
    USER_PASSWORDRESET_SUCCESS,
    USER_PASSWORDRESET_FAIL,

    USER_CPASSWORDRESET_REQUEST,
    USER_CPASSWORDRESET_SUCCESS,
    USER_CPASSWORDRESET_FAIL,

} from '../constants/userConstants'

import axios from 'axios'


export const login = (email, password) => async (dispatch) => {
    try{
        dispatch({type: USER_LOGIN_REQUEST})

        const config = {
            headers:{
                'Content-type':'application/json'
            }
        }
        const {data} = await axios.post(
            '/api/users/login',
            {'username':email, 'password':password},
            config
        )

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    }catch(error){
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({type: USER_LOGOUT})
    dispatch({type: USER_DETAIL_RESET})
}


export const updateUserProfile = (user) => async (dispatch, getState) => {
    try{
        dispatch({type: USER_UPDATE_PROFILE_REQUEST})

        const {
            userLogin: {userInfo},
        } = getState()

        const config = {
            headers:{
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const {data} = await axios.put(
            `/api/users/profile/update`,
            user,
            config,
        )

        dispatch({
            type: USER_UPDATE_PROFILE_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    }catch(error){
        dispatch({
            type: USER_UPDATE_PROFILE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const userPasswordReset = (email) => async (dispatch) => {
    try{
        dispatch({type: USER_PASSWORDRESET_REQUEST})

        const {data} = await axios.get(`/api/users/password_reset/${email}/`)

        dispatch({
            type: USER_PASSWORDRESET_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: USER_PASSWORDRESET_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const userConfirmPasswordReset = (id, user) => async (dispatch) => {
    try{
        dispatch({type: USER_CPASSWORDRESET_REQUEST})


        const {data} = await axios.put(
            `/api/users/confirm_password_reset/${id}/`,
            user
        )

        dispatch({
            type: USER_CPASSWORDRESET_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({
            type: USER_CPASSWORDRESET_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}