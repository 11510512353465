import { 
    CONTENIDO_VIDEOS_LIST_FAIL,
    CONTENIDO_VIDEOS_LIST_REQUEST,
    CONTENIDO_VIDEOS_LIST_SUCCESS,

} from '../constants/contenidoConstants'

export const contenidoVideosListReducer = (state = {videos1:[],videos2:[]}, action) =>{
    switch(action.type){
        case CONTENIDO_VIDEOS_LIST_REQUEST:
            return {loading:true, videos1:[], videos2:[]} 

        case CONTENIDO_VIDEOS_LIST_SUCCESS:
            return {loading:false, 
                videos1: action.payload.videos1,
                videos2: action.payload.videos2
            }

        case CONTENIDO_VIDEOS_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}