export const TRAMITE_LIST_REQUEST = 'TRAMITE_LIST_REQUEST'
export const TRAMITE_LIST_SUCCESS = 'TRAMITE_LIST_SUCCESS'
export const TRAMITE_LIST_FAIL = 'TRAMITE_LIST_FAIL'

export const ARCHIVO_LIST_REQUEST = 'ARCHIVO_LIST_REQUEST'
export const ARCHIVO_LIST_SUCCESS = 'ARCHIVO_LIST_SUCCESS'
export const ARCHIVO_LIST_FAIL = 'ARCHIVO_LIST_FAIL'

export const COMENTARIO_LIST_REQUEST = 'COMENTARIO_LIST_REQUEST'
export const COMENTARIO_LIST_SUCCESS = 'COMENTARIO_LIST_SUCCESS'
export const COMENTARIO_LIST_FAIL = 'COMENTARIO_LIST_FAIL'

export const COMENTARIO_CREATE_REQUEST = 'COMENTARIO_CREATE_REQUEST'
export const COMENTARIO_CREATE_SUCCESS = 'COMENTARIO_CREATE_SUCCESS'
export const COMENTARIO_CREATE_FAIL = 'COMENTARIO_CREATE_FAIL'
export const COMENTARIO_CREATE_RESET = 'COMENTARIO_CREATE_RESET'

export const TRAMITE_DETAIL_REQUEST = 'TRAMITE_DETAIL_REQUEST'
export const TRAMITE_DETAIL_SUCCESS = 'TRAMITE_DETAIL_SUCCESS'
export const TRAMITE_DETAIL_FAIL = 'TRAMITE_DETAIL_FAIL'

export const TRAMITE_CREATE_REQUEST= 'TRAMITE_CREATE_REQUEST'
export const TRAMITE_CREATE_SUCCESS= 'TRAMITE_CREATE_SUCCESS'
export const TRAMITE_CREATE_FAIL= 'TRAMITE_CREATE_FAIL'
export const TRAMITE_CREATE_RESET = 'TRAMITE_CREATE_RESET'