import { 
    TITULAR_LIST_FAIL,
    TITULAR_LIST_REQUEST,
    TITULAR_LIST_SUCCESS,

    TITULAR_DETAIL_REQUEST,
    TITULAR_DETAIL_SUCCESS,
    TITULAR_DETAIL_FAIL,

    TITULAR_USER_REQUEST,
    TITULAR_USER_SUCCESS,
    TITULAR_USER_FAIL,

    TITULAR_UPDATE_REQUEST,
    TITULAR_UPDATE_SUCCESS,
    TITULAR_UPDATE_FAIL,

    TITULAR_CREATE_REQUEST,
    TITULAR_CREATE_SUCCESS,
    TITULAR_CREATE_FAIL,

} from '../constants/titularConstants'
import axios from 'axios'

export const listTitulares = (cliente, keyword = '') => async (dispatch) => {
    try{
        dispatch({type: TITULAR_LIST_REQUEST})

        const {data} = await axios.get(`/api/titulares/cliente/${cliente}${keyword}`)

        dispatch({
            type: TITULAR_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: TITULAR_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listTitularUser = (id) => async (dispatch) => {
    try{
        dispatch({type: TITULAR_USER_REQUEST})

        const {data} = await axios.get(`/api/titulares/info/${id}`)

        dispatch({
            type: TITULAR_USER_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: TITULAR_USER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}
export const listTitularDetail = (usuario) => async (dispatch) => {
    try{
        dispatch({type: TITULAR_DETAIL_REQUEST})

        const {data} = await axios.get(`/api/titulares/${usuario}`)

        dispatch({
            type: TITULAR_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: TITULAR_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const updateTitular = (titular) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TITULAR_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/titulares/update/${titular._id}/`,
            titular,
            config
        )
        dispatch({
            type: TITULAR_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: TITULAR_DETAIL_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: TITULAR_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createTitular = (titular) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TITULAR_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/titulares/create/`,
            titular,
            config
        )
        dispatch({
            type: TITULAR_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: TITULAR_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

