export const TITULAR_LIST_REQUEST = 'TITULAR_LIST_REQUEST'
export const TITULAR_LIST_SUCCESS = 'TITULAR_LIST_SUCCESS'
export const TITULAR_LIST_FAIL = 'TITULAR_LIST_FAIL'

export const TITULAR_DETAIL_REQUEST = 'TITULAR_DETAIL_REQUEST'
export const TITULAR_DETAIL_SUCCESS = 'TITULAR_DETAIL_SUCCESS'
export const TITULAR_DETAIL_FAIL = 'TITULAR_DETAIL_FAIL'

export const TITULAR_USER_REQUEST = 'TITULAR_USER_REQUEST'
export const TITULAR_USER_SUCCESS = 'TITULAR_USER_SUCCESS'
export const TITULAR_USER_FAIL = 'TITULAR_USER_FAIL'

export const TITULAR_UPDATE_REQUEST= 'TITULAR_UPDATE_REQUEST'
export const TITULAR_UPDATE_SUCCESS= 'TITULAR_UPDATE_SUCCESS'
export const TITULAR_UPDATE_FAIL= 'TITULAR_UPDATE_FAIL'
export const TITULAR_UPDATE_RESET = 'TITULAR_UPDATE_RESET'

export const TITULAR_CREATE_REQUEST= 'TITULAR_CREATE_REQUEST'
export const TITULAR_CREATE_SUCCESS= 'TITULAR_CREATE_SUCCESS'
export const TITULAR_CREATE_FAIL= 'TITULAR_CREATE_FAIL'
export const TITULAR_CREATE_RESET = 'TITULAR_CREATE_RESET'