import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

import moment from 'moment';

function SeguroPolizaGMM({poliza}) {
    function formatearFecha(fecha) {
        const momentFecha = moment(fecha, 'YYYY-MM-DD');
        const fechaFormateada = momentFecha.format('DD/MM/YYYY');
        return fechaFormateada;
    }
  return (
    <Card>
        <div className='p-3'>
            <h6>Detalles de la Póliza:</h6>
            <hr/>
            <Row>
                <Col sm={12} lg={6}>
                    <strong>Numero: </strong><span>{poliza.numero}</span>
                </Col>
                <Col sm={12} lg={6}>
                    <strong>Tabulador: </strong><span>{poliza.tabulador}</span>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={6}>
                    <strong>Inicio Vigencia: </strong><span>{formatearFecha(poliza.inicioVigencia)}</span>
                </Col>
                <Col sm={12} lg={6}>
                    <strong>Fin Vigencia: </strong><span>{formatearFecha(poliza.finVigencia)}</span>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={6}>
                    <strong>Suma Asegurada: </strong><span>{currency(poliza.sumaAsegurada)} MXN</span>
                </Col>
                <Col sm={12} lg={6}>
                    <strong>Plan Hospitalario: </strong><span>{poliza.planHospitalario}</span>
                </Col>
            </Row>
            <br/>
        </div>
    </Card>
  )
}

export default SeguroPolizaGMM

const currency = function(number){
    return new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD', minimumFractionDigits: 2}).format(number);
};