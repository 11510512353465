import React,  {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, FloatingLabel, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import FormContainer from '../../components/FormContainer'

import { login } from '../../actions/userActions'
import { USER_CPASSWORDRESET_RESET, USER_PASSWORDRESET_RESET } from '../../constants/userConstants'

function LoginScreen() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  let navigate = useNavigate()

  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const {error, loading, userInfo} = userLogin

  useEffect(() =>{
    if(userInfo){
      dispatch({ type: USER_CPASSWORDRESET_RESET })
      dispatch({ type: USER_PASSWORDRESET_RESET })
      if(userInfo.isTitular){   
        navigate(`/t-home`)
      }else if(userInfo.isMedico){
        navigate(`/m-home`)
      }else if(userInfo.isRH){
        navigate(`/r-home`)
      }else{
        navigate(`/s-home`)
      }
    }
  }, [dispatch, navigate, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }
  return (
    <FormContainer>
      <br/>
      <h1>Inicia Sesión</h1>
      <br/>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader/>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <FloatingLabel
            label="Dirección de Correo"
            className="mb-3"
          >
            <Form.Control
            type= 'email'
            value={email}
            onChange={(e)=> setEmail(e.target.value)}
            ></Form.Control>
          </FloatingLabel>
        </Form.Group>
        <br/>
        <Form.Group controlId='password'>
          <FloatingLabel
            label="Contraseña"
            className="mb-3"
          >
          <Form.Control
            type= 'password'
            onChange={(e)=> setPassword(e.target.value)}
          ></Form.Control>
          </FloatingLabel> 
        </Form.Group>
        <Row className='text-end'>
          <Col>
            <Link to={'/olvidaste-contrasena'} style={{textDecoration:'None'}}>
              ¿Olvidaste tu contraseña?
            </Link>
          </Col>
        </Row>
        <br/><br/>
        <Row>
          <Col sm={2} lg={6}>
            <Button style={{background:'#47d7ac', width:'100%'}} type='submit'>
              Inicia Sesión
            </Button>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  )
}

export default LoginScreen