import { 
    TITULAR_LIST_FAIL,
    TITULAR_LIST_REQUEST,
    TITULAR_LIST_SUCCESS,

    TITULAR_DETAIL_REQUEST,
    TITULAR_DETAIL_SUCCESS,
    TITULAR_DETAIL_FAIL,

    TITULAR_USER_REQUEST,
    TITULAR_USER_SUCCESS,
    TITULAR_USER_FAIL,

    TITULAR_UPDATE_REQUEST,
    TITULAR_UPDATE_SUCCESS,
    TITULAR_UPDATE_FAIL,
    TITULAR_UPDATE_RESET,

    TITULAR_CREATE_REQUEST,
    TITULAR_CREATE_SUCCESS,
    TITULAR_CREATE_FAIL, 
    TITULAR_CREATE_RESET,

} from '../constants/titularConstants'

export const titularListReducer = (state = {titulares:[]}, action) =>{
    switch(action.type){
        case TITULAR_LIST_REQUEST:
            return {loading:true, titulares:[]}

        case TITULAR_LIST_SUCCESS:
            return {loading:false, 
                titulares: action.payload.titulares, 
                page: action.payload.page, 
                pages: action.payload.pages
            }

        case TITULAR_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const titularDetailReducer= (state = {titular:[]}, action) =>{
    switch(action.type){
        case TITULAR_DETAIL_REQUEST:
            return {loading:true, ...state}

        case TITULAR_DETAIL_SUCCESS:
            return {loading:false, titular: action.payload}

        case TITULAR_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const titularUserReducer= (state = {titular:[]}, action) =>{
    switch(action.type){
        case TITULAR_USER_REQUEST:
            return {loading:true, ...state}

        case TITULAR_USER_SUCCESS:
            return {loading:false, titular: action.payload}

        case TITULAR_USER_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const titularUpdateReducer = (state = { titular: {} }, action) => {
    switch (action.type) {
        case TITULAR_UPDATE_REQUEST:
            return { loading: true, ...state }

        case TITULAR_UPDATE_SUCCESS:
            return { loading: false, success: true, titular: action.payload }

        case TITULAR_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case TITULAR_UPDATE_RESET:
            return { titular: []}

        default:
            return state
    }
}

export const titularCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TITULAR_CREATE_REQUEST:
            return { loading: true }

        case TITULAR_CREATE_SUCCESS:
            return { loading: false, success: true, titular: action.payload }

        case TITULAR_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case TITULAR_CREATE_RESET:
            return {}

        default:
            return state
    }
}