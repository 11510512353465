import React, {useState, useEffect} from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import Message from '../../../components/Message'
import { updateUserProfile } from '../../../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants'

function ProfileScreen() {
  const dispatch = useDispatch()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState('')
  const [variant, setVariant] = useState('')

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const userUpdate = useSelector(state => state.userUpdate)
  const { success } = userUpdate

  useEffect(() =>{
    if (success){
      setVariant('success')
      setMessage('Contraseña Actualizada')
      setPassword('')
      setConfirmPassword('')
      dispatch({ type: USER_UPDATE_PROFILE_RESET })
    }

  }, [dispatch, success])

  const submitHandler = (e) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      setVariant('danger')
      setMessage('Las Contraseñas no son iguales')
    }else{
      dispatch(updateUserProfile({
        'id':userInfo.id,
        'password': password
    }))
    }
  }

  return (
    <div className='pt-5'>
      <Container>
        <Row>
          <Col md={3}>
            <Row>
              <h4>Perfil de Usuario</h4>
            </Row>
            <hr/>
            <h6>Cambio de Contraseña</h6>
            <hr/>
            {message && <Message variant={variant}>{message}</Message>}
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='password'>
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Ingresa Contraseña'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <br/>
                <Form.Group controlId='passwordConfirm'>
                    <Form.Label>Comfirma Contraseña</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Confirma Contraseña'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <br/>
                <Button type='submit' variant='success'>Guardar Cambios</Button>
            </Form>
            <br/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ProfileScreen