import React, {useEffect} from 'react'
import {Button, Form, Row, Col, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { listTitularUser } from '../../../actions/titularActions'
import { listTramites } from '../../../actions/tramiteActions'
import Loader from '../../../components/Loader'
import Message from '../../../components/Message'

function TramitesScreen() {
  
  const dispatch = useDispatch()

  const titularUser = useSelector(state => state.titularUser)
  const{titular} = titularUser

  const tramiteList = useSelector(state => state.tramiteList)
  const{loading, error, tramites} = tramiteList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let clave = 0
  if(userInfo){
    clave = userInfo.id
  }

  useEffect(()=>{
    if(!titular.nombres){
      dispatch(listTitularUser(clave))
    } else{
      dispatch(listTramites(titular._id))
    }
  }, [dispatch, titular], clave)


  return (
    <div className='pt-5'>
      <h4>Historial de Trámites</h4>
      <hr/>
      <br/>
      {loading ? <Loader/>
       : error ? <Message variant='danger'>{error}</Message>
        :
        tramites.length>0 ? (
          <Table striped responsive className='table-sm table-responsive'>
            <thead>
                <tr className='text-center'>
                    <th>Folio</th>
                    <th>Trámite</th>
                    <th>Asegurado</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
              {tramites.map(tramite => (
                <tr key={tramite._id} className='text-center'>
                  <td><Link to={`/t-tramites/${tramite._id}`} style={{textDecoration:'None', color:'#248BF2'}}>{tramite.folio}</Link></td>
                  <td>{tramite.tipoTramite}</td>
                  <td>{tramite.aseguradoAfectado}</td>
                  <td>{tramite.status}</td>
                  <td><i className='fas fa-trash-can'/></td>
                </tr>
              ))}
            </tbody>
          </Table>
        ):
        (
          <Row className='text-center'>
            <Col lg={8}>
              <Message variant={'warning'}>No hay Trámites registrados</Message>
            </Col>
            <Col className='p-2' lg={2}>
              <Link to={'/t-reembolso'} style={{width:'100%'}} className='btn btn-sm btn-dark'>Trámite Reembolso</Link>
              <br/>
            </Col>
            <Col className='p-2' lg={2}>
              <Link to={'/t-cirugia'} style={{width:'100%'}} className='btn btn-sm btn-dark'>Trámite P. de Cirugía</Link>
              <br/>
            </Col>
          </Row>
        )
        
      }
      
    </div>
  )
}

export default TramitesScreen