import React, {useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import VideoCard from '../../components/VideoCard'
import Slider from "react-slick";
import './index.css';
import {listContenidoVideos} from '../../actions/contenidoActions'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader.js';
import Message from '../../components/Message.js';

function GuiasScreen() {
  
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const contenidoVideosList = useSelector(state => state.contenidoVideosList)
    const {error, loading, videos1, videos2} = contenidoVideosList

    useEffect(()=>{
      dispatch(listContenidoVideos(userInfo.idCliente))
    }, [dispatch, userInfo.idCliente])

    const settings = { 
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <div className='pt-5'>
        <Container>
            <h4>FORMATOS (ESP)</h4>
            <hr/>
            <Row>
                {loading ? <Loader/>
                  : error ? <Message variant="danger">{error}</Message>
                  :
                <div>
                <Slider {...settings}>
                  
                    {videos1.map(video => (
                        <Col key={video._id} sm={12} md={6} lg={4} xl={3}>
                            <VideoCard video={video} loading={loading} error={error} />
                        </Col>
                    ))} 
                </Slider> 
                </div>
                }
            </Row>
            <br/>
            <h4>FORMATOS (ENG)</h4>
            <hr/>
            <Row>
                {loading ? <Loader/>
                  : error ? <Message variant="danger">{error}</Message>
                  :
                <div>
                <Slider {...settings}>
                  {videos2.map(video => (
                      <Col key={video._id} sm={12} md={6} lg={4} xl={3}>
                          <VideoCard video={video} loading={loading} error={error} />
                      </Col>
                  ))}
                </Slider>
                </div>
                }
            </Row>
        </Container>
    </div>
  )
}

export default GuiasScreen