import { 
    USER_LOGIN_REQUEST, 
    USER_LOGIN_SUCCESS, 
    USER_LOGIN_FAIL, 
    
    USER_LOGOUT,

    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS, 
    USER_UPDATE_PROFILE_FAIL, 
    USER_UPDATE_PROFILE_RESET,

    USER_PASSWORDRESET_REQUEST,
    USER_PASSWORDRESET_SUCCESS,
    USER_PASSWORDRESET_FAIL,
    USER_PASSWORDRESET_RESET,

    USER_CPASSWORDRESET_REQUEST,
    USER_CPASSWORDRESET_SUCCESS,
    USER_CPASSWORDRESET_FAIL,
    USER_CPASSWORDRESET_RESET,

} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) =>{
    switch(action.type){
        case USER_LOGIN_REQUEST:
            return {loading:true}

        case USER_LOGIN_SUCCESS:
            return {loading:false, userInfo: action.payload}

        case USER_LOGIN_FAIL:
            return {loading:false, error: action.payload}

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}

export const userUpdateProfileReducer = (state = {}, action) =>{
    switch(action.type){
        case USER_UPDATE_PROFILE_REQUEST:
            return {loading:true}

        case USER_UPDATE_PROFILE_SUCCESS:
            return {loading:false, success:true, userInfo: action.payload}

        case USER_UPDATE_PROFILE_FAIL:
            return {loading:false, error: action.payload}

        case USER_UPDATE_PROFILE_RESET:
            return {}

        default:
            return state
    }
}

export const userPasswordResetReducer = (state = {}, action) =>{
    switch(action.type){
        case USER_PASSWORDRESET_REQUEST:
            return {loading:true}

        case USER_PASSWORDRESET_SUCCESS:
            return {loading:false, 
                email: action.payload,
            }

        case USER_PASSWORDRESET_FAIL:
            return {loading:false, error: action.payload}

        case USER_PASSWORDRESET_RESET:
            return {}

        default:
            return state
    }
}

export const userConfirmPasswordResetReducer = (state = {}, action) =>{
    switch(action.type){
        case USER_CPASSWORDRESET_REQUEST:
            return {loading:true}

        case USER_CPASSWORDRESET_SUCCESS:
            return {loading:false, 
                message: action.payload.message,
            }

        case USER_CPASSWORDRESET_FAIL:
            return {loading:false, error: action.payload}

        case USER_CPASSWORDRESET_RESET:
            return {}

        default:
            return state
    }
}
