import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { 
    userLoginReducer,
    userUpdateProfileReducer,
    userPasswordResetReducer,
    userConfirmPasswordResetReducer,
} from './reducers/userReducers'

import {
    titularUserReducer,
    titularDetailReducer,
    titularListReducer,
    titularUpdateReducer,
    titularCreateReducer,
} from './reducers/titularReducers'

import {
    dependienteListReducer,
} from './reducers/dependienteReducers'

import {
    tramiteListReducer,
    comenatarioListReducer,
    comentarioCreateReducer,
    tramiteDetailReducer,
    tramiteCreateReducer,
    archivoListReducer,
} from './reducers/tramiteReducers'

import {
    polizaGMMDetailReducer,
} from './reducers/polizaGMMReducers'

import {
    contenidoVideosListReducer,
} from './reducers/contenidoReducers'



import {
    contactoListReducer,
    contactoDetailReducer,
    contactoEnviarCorreoReducer,
} from './reducers/contactoReducers'

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userUpdate: userUpdateProfileReducer,
    userPassReset: userPasswordResetReducer,
    userCPassReset: userConfirmPasswordResetReducer,

    titularList: titularListReducer,
    titularDetail: titularDetailReducer,
    titularUser: titularUserReducer,
    titularUpdate: titularUpdateReducer,
    titularCreate: titularCreateReducer,

    dependienteList: dependienteListReducer,

    tramiteList: tramiteListReducer,
    comentarioList: comenatarioListReducer,
    comentarioCreate: comentarioCreateReducer,
    tramiteDetail: tramiteDetailReducer,
    tramiteCreate: tramiteCreateReducer,
    archivoList: archivoListReducer,

    polizaGMMDetail: polizaGMMDetailReducer,

    contenidoVideosList: contenidoVideosListReducer,

    contactoList: contactoListReducer,
    contactoDetail: contactoDetailReducer,
    contactoEnviarCorreo: contactoEnviarCorreoReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userLogin: {userInfo: userInfoFromStorage}
}

const middleware = [thunk]

const store = createStore(reducer, initialState, 
    composeWithDevTools(applyMiddleware(...middleware)))

export default store