import { 
    TRAMITE_LIST_FAIL,
    TRAMITE_LIST_REQUEST,
    TRAMITE_LIST_SUCCESS,

    ARCHIVO_LIST_FAIL,
    ARCHIVO_LIST_REQUEST,
    ARCHIVO_LIST_SUCCESS,

    COMENTARIO_LIST_FAIL,
    COMENTARIO_LIST_REQUEST,
    COMENTARIO_LIST_SUCCESS,

    COMENTARIO_CREATE_FAIL,
    COMENTARIO_CREATE_REQUEST,
    COMENTARIO_CREATE_SUCCESS,
    COMENTARIO_CREATE_RESET,

    TRAMITE_DETAIL_REQUEST,
    TRAMITE_DETAIL_SUCCESS,
    TRAMITE_DETAIL_FAIL,

    TRAMITE_CREATE_REQUEST,
    TRAMITE_CREATE_SUCCESS,
    TRAMITE_CREATE_FAIL, 
    TRAMITE_CREATE_RESET,

} from '../constants/tramiteConstants'

export const tramiteListReducer= (state = {tramites:[]}, action) =>{
    switch(action.type){
        case TRAMITE_LIST_REQUEST:
            return {loading:true, ...state}

        case TRAMITE_LIST_SUCCESS:
            return {loading:false, tramites: action.payload}

        case TRAMITE_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const tramiteDetailReducer= (state = {tramite:[]}, action) =>{
    switch(action.type){
        case TRAMITE_DETAIL_REQUEST:
            return {loading:true, ...state}

        case TRAMITE_DETAIL_SUCCESS:
            return {loading:false, tramite: action.payload}

        case TRAMITE_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const tramiteCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TRAMITE_CREATE_REQUEST:
            return { loading: true }

        case TRAMITE_CREATE_SUCCESS:
            return { loading: false, success: true, tramite: action.payload }

        case TRAMITE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case TRAMITE_CREATE_RESET:
            return {}

        default:
            return state
    }
} 

export const comenatarioListReducer= (state = {comentarios:[]}, action) =>{
    switch(action.type){
        case COMENTARIO_LIST_REQUEST:
            return {loading:true, ...state}

        case COMENTARIO_LIST_SUCCESS:
            return {loading:false, comentarios: action.payload}

        case COMENTARIO_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const comentarioCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case COMENTARIO_CREATE_REQUEST:
            return { loading: true }

        case COMENTARIO_CREATE_SUCCESS:
            return { loading: false, success: true, comentario: action.payload }

        case COMENTARIO_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case COMENTARIO_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const archivoListReducer= (state = {}, action) =>{
    switch(action.type){
        case ARCHIVO_LIST_REQUEST:
            return {loading:true, ...state}

        case ARCHIVO_LIST_SUCCESS:
            return {loading:false, archivos: action.payload.archivos, status: action.payload.status}

        case ARCHIVO_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}