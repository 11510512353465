export const DEPENDIENTE_LIST_REQUEST = 'DEPENDIENTE_LIST_REQUEST'
export const DEPENDIENTE_LIST_SUCCESS = 'DEPENDIENTE_LIST_SUCCESS'
export const DEPENDIENTE_LIST_FAIL = 'DEPENDIENTE_LIST_FAIL'

export const DEPENDIENTE_DETAIL_REQUEST = 'DEPENDIENTE_DETAIL_REQUEST'
export const DEPENDIENTE_DETAIL_SUCCESS = 'DEPENDIENTE_DETAIL_SUCCESS'
export const DEPENDIENTE_DETAIL_FAIL = 'DEPENDIENTE_DETAIL_FAIL'

export const DEPENDIENTE_USER_REQUEST = 'DEPENDIENTE_USER_REQUEST'
export const DEPENDIENTE_USER_SUCCESS = 'DEPENDIENTE_USER_SUCCESS'
export const DEPENDIENTE_USER_FAIL = 'DEPENDIENTE_USER_FAIL'

export const DEPENDIENTE_UPDATE_REQUEST= 'DEPENDIENTE_UPDATE_REQUEST'
export const DEPENDIENTE_UPDATE_SUCCESS= 'DEPENDIENTE_UPDATE_SUCCESS'
export const DEPENDIENTE_UPDATE_FAIL= 'DEPENDIENTE_UPDATE_FAIL'
export const DEPENDIENTE_UPDATE_RESET = 'DEPENDIENTE_UPDATE_RESET'

export const DEPENDIENTE_CREATE_REQUEST= 'DEPENDIENTE_CREATE_REQUEST'
export const DEPENDIENTE_CREATE_SUCCESS= 'DEPENDIENTE_CREATE_SUCCESS'
export const DEPENDIENTE_CREATE_FAIL= 'DEPENDIENTE_CREATE_FAIL'
export const DEPENDIENTE_CREATE_RESET = 'DEPENDIENTE_CREATE_RESET'