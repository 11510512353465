import React, { useState, useEffect } from 'react'
import FormContainer from '../../../components/FormContainer'
import { Form, Button, FloatingLabel, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { userConfirmPasswordReset } from '../../../actions/userActions'
import Message from '../../../components/Message'

function ConfirmPasswordResetScreen() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState('')
  const [variant, setVariant] = useState('')

  const dispatch = useDispatch()
  const match = useParams()

  const userCPassReset = useSelector(state => state.userCPassReset)
  const { message: userMessage, success } = userCPassReset

  useEffect(() =>{
    if (message === '' && userMessage === 'Success'){
      setVariant('success')
      setMessage('La contraseña se cambio con exito')
      setPassword('')
      setConfirmPassword('')
    }
    else if(message === '' && userMessage === 'Fail'){
      setVariant('warning')
      setMessage('El Token no es válido o ya ha sido utilizado')
      setPassword('')
      setConfirmPassword('')
    }
  }, [dispatch, success, userMessage, message])

  const submitHandler = (e) => {
    e.preventDefault()

    setMessage('')
    if (password !== confirmPassword) {
      setVariant('danger')
      setMessage('Las Contraseñas no son iguales')
    }else{
      dispatch(userConfirmPasswordReset(match.id, {
            'token':match.token,
            'password': password
        }))
    }
  }
  return (
    <FormContainer>
        <br/>
        <h1>Restablecer Contraseña</h1>
        <br/>
        {message && <Message variant={variant}>{message}</Message>}
        <Form onSubmit={submitHandler}>
            <br/>
            <Form.Group controlId='password'>
                <FloatingLabel
                label="Nueva contraseña"
                className="mb-3"
                >
                <Form.Control
                type= 'password'
                required
                onChange={(e)=> setPassword(e.target.value)}
                ></Form.Control>
                </FloatingLabel>
            </Form.Group>
            <br/>
            <Form.Group controlId='confirmPassword'>
                <FloatingLabel
                label="Confirma tu contraseña"
                className="mb-3"
                >
                <Form.Control
                type= 'password'
                required
                onChange={(e)=> setConfirmPassword(e.target.value)}
                ></Form.Control>
                </FloatingLabel>
            </Form.Group>
            <br/>
            <Row>
                <Col sm={2} lg={6}>
                    <Button className='btn' style={{background:'#47d7ac', width:'100%'}} type='submit'>
                        Cambiar contraseña
                    </Button>
                    <br/>
                    <br/>
                </Col>
                <Col sm={2} lg={6}>
                    <Link to={'/login'} className='btn btn-light' style={{width:'100%'}} variant='light'>
                        Iniciar Sesión
                    </Link>
                </Col>
            </Row>
        </Form>
    </FormContainer>
  )
}

export default ConfirmPasswordResetScreen