import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const images = [
  {
    url: 'https://d1ih8jugeo2m5m.cloudfront.net/2022/02/Poder-de-negociacion-de-los-proveedores-1200x685.jpg',
    title: 'Mis Asegurados',
    icon: 'fas fa-user-group',
    link: 'proveedores',
    width: '32%',
  },
  {
    url: '',
    title: 'Alta de Asegurado',
    icon: 'fas fa-user-plus',
    link: 'documentos',
    width: '32%',
  },
  {
    url: 'https://1.bp.blogspot.com/-NcrLTrB6_6E/WgrrkY3CfII/AAAAAAAAH3A/BMkf5f2e0csh3YwBvh-CrSfA0MczRtGZQCLcBGAs/s1600/Contratos%2Bhabituales%2Ben%2Blos%2BNegocios%2BInternacionales.jpg',
    title: 'Alta Masiva',
    icon: 'fas fa-house-user',
    link: 'contratos',
    width: '32%',
  },
  {
    url: '',
    title: 'Guias y Formatos',
    icon: 'fa-solid fa-chalkboard-user',
    link: 'notificaciones',
    width: '32%',
  },
  {
    url: 'https://assets.website-files.com/60e524ba8a4231e0f4aa7cb0/6297865fd4de97affe8187dc_Conoce%20las%20Fianzas%20Judiciales%20no%20Penales.png',
    title: 'Tramites',
    icon: 'fa-solid fa-user-doctor',
    link: 'fianzas',
    width: '32%',
  },
  {
    url: '',
    title: 'Mi Perfil',
    icon: 'fa-solid fa-user',
    link: 'polizas',
    width: '32%',
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

export default function MenuMedicoScreen() {
  
  return (
    <div className='pt-5'>
        <h4>Menu Principal</h4>
        <hr/>
        <Box className='d-flex text-center' sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
        {images.map((image) => (
            <ImageButton
              focusRipple
              className='m-2'
              key={image.title}
              style={{
                width: image.width,
              }} 
            >
              <ImageSrc style={{ backgroundColor:'#003a70', backgroundImage: `url(${image.url})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Image>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  sx={{
                    position: 'relative',
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  }}
                >
                  <i className={`${image.icon}`} style={{fontSize:'3vh'}}></i> {image.title}
                  <ImageMarked className="MuiImageMarked-root" />
                </Typography>
              </Image>
            </ImageButton>
        ))}
        </Box>
    </div>
      
  );
}
