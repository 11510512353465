import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listTitularUser } from '../../../actions/titularActions'
import { listDependientes } from '../../../actions/dependienteActions';
import { createTramite } from '../../../actions/tramiteActions'
import { TRAMITE_CREATE_RESET } from '../../../constants/tramiteConstants'

function ReembolsoScreen() {
    let navigate = useNavigate()
    const dispatch = useDispatch()

    const [asegurado, setAsegurado] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [file1, setFile1] = useState('')
    const [file2, setFile2] = useState('')
    const [file3, setFile3] = useState('')
    const [file4, setFile4] = useState('')
    const [file5, setFile5] = useState('')
    const [file6, setFile6] = useState('')
    const [file7, setFile7] = useState('')
    const [file8, setFile8] = useState('')

    const [archivos, setArchivos] = useState({
        archivo1: '',
        archivo2: '',
        archivo3: '',
        archivo4: '',
        archivo5: '',
        archivo6: '',
        archivo7: '',
        archivo8: '',
    })

    const[ tramite, setTramite] = useState('')
    const[ err, setErr] = useState('')
    const[ success, setSuccess] = useState(false)

    const files = [file1,file2,file3,file4,file5,file5,file6,file7,file8]
    const setFiles = [setFile1,setFile2,setFile3,setFile4,setFile5,setFile6,setFile7,setFile8]
    const [numFiles, setNumFiles] = useState(0)

    const titularUser = useSelector(state => state.titularUser)
    const{error, loading, titular} = titularUser

    const dependienteList = useSelector(state => state.dependienteList)
    const{dependientes} = dependienteList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const tramiteCreate = useSelector(state => state.tramiteCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate, tramite: createdTramite } = tramiteCreate

    let clave = 0
    if(userInfo){ 
        clave = userInfo.id
    }

    useEffect(()=>{
        if (success) {
            dispatch({ type: TRAMITE_CREATE_RESET })
            navigate(`/t-confirmacion/${tramite._id}`)
        } else {
            if(!titular.nombres){
                dispatch(listTitularUser(clave))
            } else{
                dispatch(listDependientes(titular._id))
            }
        }
    }, [dispatch, titular, createdTramite, success], clave)

    const submitHandler = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('titular', titular._id)
        formData.append('asegurado', asegurado)
        formData.append('descripcion', descripcion)
        formData.append('autor', 'Titular')
        formData.append('tipoTramite', 'Reembolso')
        for (let archivo in archivos) {
            formData.append(archivo, archivos[archivo]);
        }
        try {

            const config = {
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post('/api/tramites/create/', formData, config)


            setTramite(data)
            setSuccess(true)

        } catch (error) {
            setErr(error)
        }
    }

    const uploadFileHandler = async (e) => {
        setFiles[e.target.id]('fa-regular fa-circle-check')

        const selectedFile = e.target.files[0];
        setArchivos({
            ...archivos,
            [e.target.name]: selectedFile
        })

    }

    useEffect(() =>{
        files.forEach(file => {
            if(file !== '' && numFiles<9){
                setNumFiles(numFiles+1) 
            }
        })
    },[file1,file2,file3,file4,file5,file6,file7,file8])

  return (
    <div className='pt-5'>
        <Container>
            <h4>Iniciar trámite de Reembolso</h4>
            <hr/>
            <br/>
            <Form onSubmit={submitHandler}>
                <h6>Selección del afectado</h6>
                <hr/>
                <Form.Group>
                    <Form.Label>Asegurado Afectado</Form.Label>
                    <Form.Select aria-label="Default select example" 
                        value={asegurado}
                        onChange={(e) => setAsegurado(e.target.value)}
                    >
                        <option value='titular'>{titular.nombres} {titular.apellidoPaterno} {titular.apellidoMaterno}</option>
                        {dependientes.map(dependiente => (
                            <option key={dependiente._id} value={dependiente._id}>{dependiente.nombres} {dependiente.apellidoPaterno} {dependiente.apellidoMaterno}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <br/>
                <h6>Descripción del Siniestro</h6>
                <hr/>
                <Form.Group controlId='descripción'>
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                    as='textarea'
                    rows={3}
                    value={descripcion}
                    onChange={(e)=> setDescripcion(e.target.value)}
                    ></Form.Control>
                </Form.Group>
                <br/>
                <h6>Documentación Requerida {numFiles}/8</h6> 
                <hr/>
                <Row>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Solicitud de Reembolso <i style={{color:'#4bbf73'}} className={file1}></i></Form.Label>
                            <Form.Control
                                id='0'
                                type='file'
                                accept=".pdf"
                                name='archivo1'
                                required
                                onChange={uploadFileHandler}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Informe de Accidente o Enfermedad <i style={{color:'#4bbf73'}} className={file2}></i></Form.Label>
                            <Form.Control
                                id='1'
                                type='file'
                                accept=".pdf"
                                name='archivo2'
                                required
                                onChange={uploadFileHandler}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Informe Médico <i style={{color:'#4bbf73'}} className={file3}></i></Form.Label>
                            <Form.Control
                                id='2'
                                type='file'
                                accept=".pdf"
                                name='archivo3'
                                required
                                onChange={uploadFileHandler}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Facturas <i style={{color:'#4bbf73'}} className={file4}></i></Form.Label>
                            <Form.Control
                                id='3'
                                type='file'
                                accept=".zip"
                                name='archivo4'
                                required
                                onChange={uploadFileHandler}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Resultado de los estudios realizados <i style={{color:'#4bbf73'}} className={file5}></i></Form.Label>
                            <Form.Control
                                id='4'
                                type='file'
                                accept=".pdf"
                                name='archivo5'
                                required
                                onChange={uploadFileHandler}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Formato único de información bancaria <i style={{color:'#4bbf73'}} className={file6}></i></Form.Label>
                            <Form.Control
                                id='5'
                                type='file'
                                accept=".pdf"
                                name='archivo6'
                                required
                                onChange={uploadFileHandler}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Estado de Cuenta Bancario <i style={{color:'#4bbf73'}} className={file7}></i></Form.Label>
                            <Form.Control
                                id='6'
                                type='file'
                                accept=".pdf"
                                name='archivo7'
                                required
                                onChange={uploadFileHandler}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Credencial para votar <i style={{color:'#4bbf73'}} className={file8}></i></Form.Label>
                            <Form.Control
                                id='7'
                                type='file'
                                accept=".pdf"
                                name='archivo8'
                                required
                                onChange={uploadFileHandler}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                </Row>
                <Button className='d-flex ms-auto' type='submit' variant='success'>
                                Enviar Documentos
                </Button>
            </Form>
        </Container>
    </div>
  )
}

export default ReembolsoScreen