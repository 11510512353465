import React, { useEffect, useState }from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createTitular } from '../../../actions/titularActions'
import { useParams, useNavigate } from 'react-router-dom'
import { TITULAR_CREATE_RESET } from '../../../constants/titularConstants'
import Message from '../../../components/Message'
import Loader from '../../../components/Loader'

function AseguradosCreateScreen() {
    
    let navigate = useNavigate()
    const match = useParams()
    const dispatch = useDispatch()

    const [nombres, setNombres] = useState('')
    const [apellidoPaterno, setApellidoPaterno] = useState('')
    const [apellidoMaterno, setApellidoMaterno] = useState('')
    const [fechaNacimiento, setFechaNacimiento] = useState('')
    const [edad, setEdad] = useState('')
    const [sexo, setSexo] = useState('')
    const [rfc, setRfc] = useState('')
    const [tipoEmpleado, setTipoEmpleado] = useState('')
    const [refPoliza, setRefPoliza] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const titularCreate = useSelector(state => state.titularCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate, titular: createdTitular } = titularCreate
  
    useEffect(()=>{
        if (successCreate) {
            dispatch({ type: TITULAR_CREATE_RESET })
            navigate(`/m-asegurados/create/dependientes/${createdTitular._id}`)
        }
    }, [createdTitular,successCreate,navigate])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password !== confirmPassword) {
            setMessage('Las Contraseñas no son iguales')
        }else{
            dispatch(createTitular({ 
                nombres,
                apellidoPaterno, 
                apellidoMaterno,
                fechaNacimiento,
                edad,
                sexo,
                rfc,
                tipoEmpleado,
                refPoliza,
                email,
                password
            }))
        }

    }
  return (
    <div className='pt-5'>
        <Container>
            <Row>
                <Col lg={4}>
                    <h4>Nuevo Asegurado</h4>
                </Col>
                <Col>
                    <i className='fas fa-1'/> <span>Información del Titular</span> 
                    <i className='fas fa-arrow-right-long ms-3 me-3' style={{color:"#aaa"}}/>
                    <i className='fas fa-2' style={{color:"#aaa"}}/><span style={{color:'#aaa'}}> Información de los Depentientes</span>
                    <i className='fas fa-arrow-right-long ms-3 me-3' style={{color:"#aaa"}}/>
                    <i className='fas fa-3' style={{color:"#aaa"}}/><span style={{color:'#aaa'}}> Información de la Póliza</span>
                </Col>
            </Row>
            <hr/>
            {message && <Message variant='danger'>{message}</Message>}
            {loadingCreate && <Loader />}
            {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
            <br/>

                <Form onSubmit={submitHandler}>
                <h6>Datos Generales</h6>
                <hr/><br/>
                <Row>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Nombres</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Nombres del Asegurado'
                                value={nombres}
                                onChange={(e) => setNombres(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Apellido Paterno</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Apellido Paterno'
                                value={apellidoPaterno}
                                onChange={(e) => setApellidoPaterno(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Apellido Materno</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Apellido Materno'
                                value={apellidoMaterno}
                                onChange={(e) => setApellidoMaterno(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Fecha Nacimiento</Form.Label>
                            <Form.Control
                                required
                                type='date'
                                placeholder='Fecha Nacimiento'
                                value={fechaNacimiento}
                                onChange={(e) => setFechaNacimiento(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Edad</Form.Label>
                            <Form.Control
                                required
                                type='number'
                                placeholder='Edad'
                                value={edad}
                                onChange={(e) => setEdad(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Sexo</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Sexo'
                                value={sexo}
                                onChange={(e) => setSexo(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>RFC</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='RFC'
                                value={rfc}
                                onChange={(e) => setRfc(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Tipo de Empleado</Form.Label>
                            <Form.Select
                                required
                                onChange={(e) => setTipoEmpleado(e.target.value)}
                            >
                                <option value='Empleado'>Seleccione tipo de empleado</option>
                                <option value='Director'>Director</option>
                                <option value='Gerente'>Gerente</option>
                                <option value='Empleado'>Empleado</option>
                            </Form.Select>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Referencia Poliza</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Referencia Poliza'
                                value={refPoliza}
                                onChange={(e) => setRefPoliza(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                </Row>
                <br/>
                <h6>Datos de Acceso</h6>
                <hr/>
                <Row>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Correo</Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Correo'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Contraseña'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Confirmar Contraseña</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Confirmar Contraseña'
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <br/>
                    </Col>
                </Row>
                <Button className='d-flex ms-auto' type='submit' variant='info'>
                    Continuar
                </Button>
                </Form>
        </Container>
    </div>
  )
}

export default AseguradosCreateScreen