import { 
    POLIZA_GMM_FAIL,
    POLIZA_GMM_REQUEST,
    POLIZA_GMM_SUCCESS,
} from '../constants/polizaGMMConstants'

export const polizaGMMDetailReducer = (state = {polizaGMM:[]}, action) =>{
    switch(action.type){
        case POLIZA_GMM_REQUEST:
            return {loading:true, polizaGMM:[]}

        case POLIZA_GMM_SUCCESS:
            return {loading:false, 
                url: action.payload.url,
                polizaGMM: action.payload.polizaGMM,
            }

        case POLIZA_GMM_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}