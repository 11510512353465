import { 
    CONTACTO_LIST_FAIL,
    CONTACTO_LIST_REQUEST,
    CONTACTO_LIST_SUCCESS,

    CONTACTO_DETAIL_REQUEST,
    CONTACTO_DETAIL_SUCCESS,
    CONTACTO_DETAIL_FAIL,

    CONTACTO_ENVIARCORREO_REQUEST,
    CONTACTO_ENVIARCORREO_SUCCESS,
    CONTACTO_ENVIARCORREO_FAIL,

} from '../constants/contactoConstants'

export const contactoListReducer= (state = {contactos:[]}, action) =>{
    switch(action.type){
        case CONTACTO_LIST_REQUEST:
            return {loading:true, ...state}

        case CONTACTO_LIST_SUCCESS:
            return {loading:false, contactos: action.payload}

        case CONTACTO_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const contactoDetailReducer= (state = {contacto:[]}, action) =>{
    switch(action.type){
        case CONTACTO_DETAIL_REQUEST:
            return {loading:true, ...state}

        case CONTACTO_DETAIL_SUCCESS:
            return {loading:false, contacto: action.payload}

        case CONTACTO_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const contactoEnviarCorreoReducer= (state = {}, action) =>{
    switch(action.type){
        case CONTACTO_ENVIARCORREO_REQUEST:
            return {loading:true, ...state}

        case CONTACTO_ENVIARCORREO_SUCCESS:
            return {loading:false, mensaje: action.payload.mensaje}

        case CONTACTO_ENVIARCORREO_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}
