import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

import moment from 'moment';

function SeguroTitular({titular}) {

    function formatearFecha(fecha) {
        const momentFecha = moment(fecha, 'YYYY-MM-DD');
        const fechaFormateada = momentFecha.format('DD/MM/YYYY');
        return fechaFormateada;
    }
  return (
    <Card>
        <div className='p-3'>
            <h6>Detalles del Titular:</h6>
            <hr/>
            <Row>   
                <Col>
                    <strong>Nombre: </strong><span>{titular.nombres} {titular.apellidoPaterno} {titular.apellidoMaterno}</span>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={6}>
                    <strong>Fecha Nacimiento: </strong><span>{formatearFecha(titular.fechaNacimiento)}</span>
                </Col>
                <Col sm={12} lg={6}>
                    <strong>Edad: </strong><span>{titular.edad}</span>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={6}>
                    <strong>Sexo: </strong><span>{titular.sexo}</span>
                </Col>
                <Col sm={12} lg={6}>
                    <strong>Tipo Empleado: </strong><span>{titular.tipoEmpleado}</span>
                </Col>
            </Row>
            <br/>
        </div>
    </Card>
  )
}

export default SeguroTitular