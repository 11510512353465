import React, {useState} from 'react'
import { Button, Form } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'

function SearchBox() {

    const [keyword, setKeyword] = useState('')

    let navigate = useNavigate()
    let pathNav = useLocation().pathname

    const submitHandler = (e) => {
        e.preventDefault()
        if(keyword){
            navigate(`?keyword=${keyword}&page=1`)
        }else{
            navigate(pathNav)
        }
    }
  return (
    <Form onSubmit={submitHandler} className="d-flex">
        <Form.Control 
            type="text" 
            placeholder="Ingresa tu búsqueda" 
            name="q" 
            onChange={(e) => setKeyword(e.target.value)} 
            className="mr-ms-2 ml-ms-5"/>
        <Button type="submit" variant="success" >Buscar</Button>
    </Form>
  )
}

export default SearchBox